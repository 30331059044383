.card {
  margin: auto;
  height: 100vh;
  width: 100%;
  padding: 10vh 0px 0px 0px;
  box-sizing: border-box;
  background-color: black;
  text-align: center;
}
.icons-container {
  display: flex;
  justify-content: center;
}
.icon {
  margin: 5px;
  padding-bottom: 100px;
  width: 10%;
}
p {
  font-family: 'Montserrat', sans-serif;
}
.name {
  color: white;
  font-size: 9vw;
}
.subtitle a {
  color: #4f556f;
  font-size: 2vw;
}
.subtitle a:hover {
  color: #9fa5bb;
  cursor: pointer;
}